<template>
  <div
    class="layout-container mb-48 md:mb-64"
  >
    <div
      :class="{
        'bg-grey200 py-32 sm:py-56 rounded-[16px] px-24 sm:px-56 md:pl-[106px]' : isNewsletterForm
      }"
    >
      <div
        class="form-block"
        :class="{
          'newsletter-form' : isNewsletterForm
        }"
      >
        <div v-if="loading" class="w-full min-h-240 flex items-center">
          <div class="w-full md:text-center">
            <div
              class="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-32 w-32 inline-block mb-24 max-w-1/2 mx-auto"
            />
          </div>
        </div>
        <div
          v-show="!loading"
          ref="formContainer"
          class="w-full min-h-240"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import type { FormContainerBlock } from '~/content-types';
import epiFormsRendering from '~/util/epiFormsRendering';
  
const loading = ref(false);
const route = useRoute();
const formContainer = ref(null);
const isNewsletterForm = ref();

const props = defineProps<{
  model: FormContainerBlock;
}>();

const decodeHtmlEntity = (str: string) => {
  return str.replace(/&#(\d+);/g, function(match, dec) {
    return String.fromCharCode(dec);
  });
};

onMounted(async() => {
  loading.value = true;
  const formData = await fetch(
    `/api/episerver/v3.0/content/${props.model.contentLink.guidValue}/?languages=${props.model.language.name}&expand=*&currentPageUrl=${route.path}`,
    {
      headers: {
        'Accept-Language': props.model.language.name,
      },
    }
  );
  const res = await formData.json() as any;
  loading.value = false;

  isNewsletterForm.value = res.isNewsletter;

  const formContainerElem = formContainer.value as HTMLElement;
  epiFormsRendering.render(res.formModel, formContainerElem);
  formContainerElem
    .querySelectorAll(
      'input[type=text], input[type=email], input[type=number], textarea'
    )
    ?.forEach((el) => {
      const inputElement = el as HTMLInputElement;
      const inputElementPrev = el.previousElementSibling as HTMLInputElement;
      
      inputElement.placeholder = decodeHtmlEntity(inputElement.placeholder);

      if (
        inputElementPrev.classList.contains('Form__Element__Caption') &&
          !inputElementPrev.innerHTML
      ) {
        el.parentElement?.classList.add('no-label-value');
      }

      inputElement.addEventListener('input', (e: any) => {
        if (e?.target?.value?.length) {
          inputElement.parentElement?.classList.add('has-value');
        } else {
          inputElement.parentElement?.classList.remove('has-value');
        }
      });
    });
  formContainerElem
    .querySelectorAll('option[disabled="disabled"]')
    ?.forEach((el) => {
      const inputElement = el as HTMLOptionElement;
      inputElement.text = decodeHtmlEntity(inputElement.text);
    });

  if (isNewsletterForm.value) {
    // Create a div element
    const div = document.createElement('div');
    div.classList.add('newsletter-form-content-wrapper');

    // Move the div and button elements inside div
    const button = formContainerElem.querySelector('.FormSubmitButton');
    const divBeforeButton = button.previousElementSibling;
    button.insertAdjacentElement('afterend', div);
    div.appendChild(divBeforeButton);
    div.appendChild(button);
  }

  const epiforms = $$epiforms as any;

  if (typeof epiforms !== 'undefined') {
    epiforms(document).ready(function() {
      epiforms('.EPiServerForms').on('formsSubmitted', function() {
        (function($) {
          if (isNewsletterForm.value) {
            window.dataLayer?.push({
              event: 'newsletter_signup',
            });
          }
        })(epiforms);
      });
    });
  }
});

</script>
<style scoped>
.loader {
  border-top-color: #fff;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}
@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
<style>
.full-width-content-area {
  .form-block {
    @apply mx-auto md:w-3/4 lg:w-1/2;
    &.newsletter-form {
      @apply mx-0 lg:w-[60%];

      .Form__Title {
        @apply mb-12 sm:mb-16 text-xl leading-lg tracking-xs sm:tracking-xxs sm:text-2xl sm:leading-xl;
      }

      input {
        @apply bg-white;
      }

      .Form__Element:last-child {
        @apply mb-0;
      }

      .FormParagraphText p {
        @apply text-xs tracking-xl leading-[20px];
      }

      .Form__Description {
        @apply text-sm leading-xs tracking-xl sm:tracking-lg font-normal;
      }

      .FormSubmitButton {
        @apply h-40 w-full sm:w-auto sm:h-48 sm:ml-24;
      }

      .newsletter-form-content-wrapper {
        @apply sm:flex items-end;

        .FormSubmitButton {
          @apply mb-16;
        }
      }
    }
  }
}
</style>
